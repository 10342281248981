import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>About</h2>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
        <h3>About me</h3>
          <p>
            I am a professional language and communication specialist with a background in law, psychology and education. A native English speaker born in Auckland, New Zealand, I am fluent in three languages, having lived in China and Korea for many years before obtaining a Master’s degree in interpretation and translation from Hankuk University of Foreign Studies, the top GSIT in Asia.
          </p>

          <p>
            I provide high quality translation, interpretation and editing at a competitive price and have experience in fields such as international conferences and forums, real estate and investing, law and legal documentation, marketing and PR, theses and research articles, human rights advocacy, news media and journalism, cryptocurrencies and blockchain, and eSports and gaming.
          </p>

          <p>
            I am currently seeking new clients and business opportunities. Let me show you how I can meet your communication needs, help make your project a success or add value to your business!
          </p>

          <h3 className="major">Education</h3>
          <ul className="alt">
            <li>
              <b>Hankuk University of Foreign Studies</b><br />
              <i>Master's in Korean-English Interpretation and Translation</i><br />
              Completed courses in consecutive interpretation, simultaneous interpretation, translation of legal, financial and scientific texts, CAT tools and translation theory.
            </li>
            <li>
              <b>University of Auckland</b><br />
              <i>Bachelor’s in Law and Psychology</i><br />
              Completed courses in criminal law, public law, contract law, commercial law, family law, intellectual property law, property law, psychology and Mandarin Chinese.
            </li>
          </ul>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
